import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de 6 a 12 cm de diàmetre, cònic amb l’àpex obtús que posteriorment s’obre, viscós en temps humit i de color variable, generalment gris fumat. Les làmines són blanques, primes, lliures, un poc gruixades que es taquen de color rosat bru per la caiguda de les espores. El peu és atenuat a la part superior, amb la base un poc gruixada que presenta una volva irregular, no gaire grossa i enterrada. És de color blanc grisós. Les espores són rosades en massa, d’el·lipsoides a ovoides, de 13-18 x 7,5-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      